import { Container, Box, Typography, Grid2 as Grid } from "@mui/material";
import { Link } from "react-router-dom";

function Layout({ children, mode, companyData }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Full viewport height
        position: "relative",
      }}
    >
      {/* Header or Logo */}
      <Container
        style={{
          textAlign: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
          flex: 1, // Allow content to grow
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
          {companyData?.logoUrl ? (
            <Link to="/" style={{ display: "inline-block", width: "100%" }}>
              <img
                src={companyData?.logoUrl}
                alt="Project Logo"
                style={{
                  width: "20%",
                  maxWidth: "200px",
                }}
              />
            </Link>
          ) : null}
        </Box>

        {/* Main Content */}
        <div style={{ flex: "1" }}>{children}</div>
      </Container>

      {/* Footer */}
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          width: "100vw", // Full width of viewport
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          zIndex: 1000,
          padding: "5px 0",
          boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)", // Optional shadow
          marginTop: "auto", // Pushes footer to the bottom
        }}
      >
        <Typography
          variant="body2"
          component="p"
          style={{
            display: "flex",
            color: "black",
            alignItems: "center",
            fontSize: "1.1rem",
            textAlign: "center",
          }}
        >
          Are you a business? Create your impact gifting campaign with
          <a
            href="https://handprint.tech/solutions/impact-gifting/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: "5px" }}
          >
            <img
              src={
                mode === "dark"
                  ? "/img/handprint-white.svg"
                  : "/img/handprint-black.svg"
              }
              alt="Handprint"
              style={{ height: "37px" }}
            />
          </a>
        </Typography>
        <Grid>
          <a
            href="https://handprint.tech/terms-conditions/"
            style={{ fontSize: "0.8rem", color: "black", marginTop: "5px" }}
          >
            Terms and Conditions
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default Layout;
